<template>
  <div class="th-container">
    <!-- Set date range -->
    <section class="mb-6">
      <div class="font-bold text-sm mb-4">
        {{ $t('components.time_constraints.date_range.title') }}
      </div>

      <el-form
        ref="form"
        :rules="rules"
        :model="modelValue"
        class="flex flex-wrap"
      >
        <!-- Start date -->
        <div class="flex items-center mr-6">
          <!-- Checkbox -->
          <el-form-item
            prop="time.scheduled.date_range.start.enabled"
            class="mr-2 mb-2 xs:w-24"
          >
            <el-checkbox
              id="time.scheduled.date_range.start.enabled"
              v-model="dateRangeStartEnabled"
              @change="openDatePicker($event, 'date-picker-start')"
            >
              {{ $t('pages.discounts.edit.form.date_range.start.date') }}
            </el-checkbox>
          </el-form-item>

          <!-- Date -->
          <el-form-item
            prop="time.scheduled.date_range.start.value"
            class="w-40 mb-2"
          >
            <date-picker-input
              id="time.scheduled.date_range.start.value"
              ref="date-picker-start"
              v-model="dateRangeStart"
              class="w-full"
              :type="dateTypes.DATE_TIME"
              :date-format="computedDateTimeFormat"
              no-past-dates
              return-date-type
              :placeholder="$t('pages.discounts.edit.form.date_range.select')"
              :disabled="!dateRangeStartEnabled"
            />
          </el-form-item>
        </div>

        <!-- End date -->
        <div class="flex items-center">
          <!-- Checkbox -->
          <el-form-item
            prop="time.scheduled.date_range.end.enabled"
            class="mr-2 mb-2 xs:w-24"
          >
            <el-checkbox
              id="time.scheduled.date_range.end.enabled"
              v-model="dateRangeEndEnabled"
              @change="openDatePicker($event, 'date-picker-end')"
            >
              {{ $t('pages.discounts.edit.form.date_range.end.date') }}
            </el-checkbox>
          </el-form-item>

          <!-- Date -->
          <el-form-item
            prop="time.scheduled.date_range.end.value"
            class="w-40 mb-2"
          >
            <date-picker-input
              id="time.scheduled.date_range.end.value"
              ref="date-picker-end"
              v-model="dateRangeEnd"
              class="w-full"
              :type="dateTypes.DATE_TIME"
              :date-format="computedDateTimeFormat"
              :min-threshold="dateRangeStart"
              return-date-type
              :placeholder="$t('pages.discounts.edit.form.date_range.select')"
              :disabled="!dateRangeEndEnabled"
            />
          </el-form-item>
        </div>
      </el-form>
    </section>

    <!-- Time frame -->
    <section class="mt-4">
      <div class="font-bold text-sm mb-4">
        {{ $t('components.time_constraints.time_frame.title') }}
      </div>

      <div>
        <div v-for="day of days" :key="day">
          <div
            v-for="(slot, slotIndex) of dayOfWeekForm[day].slots"
            :key="slotIndex"
            class="flex items-center flex-wrap mb-4"
          >
            <div class="w-32 xs:w-full">
              <el-checkbox
                v-model="slot.enabled"
                @change="(v) => updateDaySlot(v, day, 'enabled', slotIndex)"
              >
                {{ translations[day] }}
              </el-checkbox>
            </div>

            <div class="flex xs:mt-2">
              <div class="flex">
                <div class="w-20 mr-4">
                  <!-- Start time -->
                  <time-input
                    :id="`${day}-start-${slotIndex}`"
                    v-model="slot.start"
                    hide-warning-icon
                    :placeholder="
                      $t('pages.discounts.edit.form.time_frame.start')
                    "
                    :disabled="!slot.enabled"
                    @update:modelValue="
                      (v) => updateDaySlot(v, day, 'start', slotIndex)
                    "
                  />
                </div>

                <div class="w-20 mr-4">
                  <!-- End time -->
                  <time-input
                    :id="`${day}-end-${slotIndex}`"
                    v-model="slot.end"
                    class="text-center"
                    hide-warning-icon
                    :placeholder="
                      $t('pages.discounts.edit.form.time_frame.end')
                    "
                    :disabled="
                      !slot.enabled || !slot.start || slot.start.length < 5
                    "
                    :initial-value="
                      slot.enabled &&
                      !slot.end &&
                      slot.start &&
                      slot.start.length === 5
                        ? '23:59'
                        : undefined
                    "
                    :validate-fn="validateEndAfterStart(slot.start)"
                    :validate-fn-error-message="
                      $t(
                        'pages.discounts.edit.form.errors.constraints.time_order'
                      )
                    "
                    @update:modelValue="
                      (v) => updateDaySlot(v, day, 'end', slotIndex)
                    "
                  />
                </div>
              </div>

              <div v-if="slot.enabled" class="flex">
                <!-- Delete -->
                <el-button
                  plain
                  icon="Delete"
                  class="-ml-2 el-button--text-icon"
                  @click="removeDaySlot(day, slotIndex)"
                />

                <!-- Add -->
                <el-button
                  v-if="slotIndex === dayOfWeekForm[day].slots.length - 1"
                  plain
                  type="primary"
                  icon="Plus"
                  class="px-3"
                  @click="addRow(day)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import safeSet from 'just-safe-set'
import safeGet from 'just-safe-get'
import { isBefore } from 'date-fns'
import TimeInput from '@/components/inputs/time-input'
import DatePickerInput from '@/components/inputs/date-picker'
import { initialSlot, DAYS, validateConstraints } from '@/utils/constraints'
import { dateTypes } from '@/constants/date'

const pathTo = (p) => `time.scheduled.${p}`

export default {
  components: {
    DatePickerInput,
    TimeInput
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    dateTimeFormat: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      refreshDateRange: false,
      dateTypes,
      days: DAYS
    }
  },
  computed: {
    rules() {
      return {
        'time.scheduled.date_range.start.value': [
          { validator: this.validateRangeStart, trigger: 'blur' },
          { validator: this.validateRangeStartBeforeEnd, trigger: 'blur' }
        ],
        'time.scheduled.date_range.end.value': [
          { validator: this.validateRangeEnd, trigger: 'blur' },
          { validator: this.validateRangeStartBeforeEnd, trigger: 'blur' }
        ]
      }
    },
    daysOfWeekEnabled: {
      get() {
        return safeGet(this.modelValue, pathTo('day_of_week.enabled'))
      },
      set(v) {
        this.setterFn('day_of_week.enabled', v)
      }
    },
    dateRangeEnabled: {
      get() {
        return safeGet(this.modelValue, pathTo('date_range.enabled'))
      },
      set(v) {
        this.setterFn('date_range.enabled', v)
      }
    },
    dateRangeStartEnabled: {
      get() {
        return safeGet(this.modelValue, pathTo('date_range.start.enabled'))
      },
      set(v) {
        this.setterFn('date_range.start.enabled', v)
      }
    },
    dateRangeEndEnabled: {
      get() {
        return safeGet(this.modelValue, pathTo('date_range.end.enabled'))
      },
      set(v) {
        this.setterFn('date_range.end.enabled', v)
      }
    },
    dateRangeStart: {
      // refreshDateRange is a variable created to trigger the reactivity of the computed method
      // as the real one is to deep nested, it is not getting triggered the reactivity
      // in the set, the variable is changed to trigger the reactivity
      get() {
        this.refreshDateRange
        return safeGet(this.modelValue, pathTo('date_range.start.value'))
      },
      set(v) {
        this.setterFn('date_range.start.value', v?.toISOString())
        this.refreshDateRange = !this.refreshDateRange
      }
    },
    dateRangeEnd: {
      // refreshDateRange is a variable created to trigger the reactivity of the computed method
      // as the real one is to deep nested, it is not getting triggered the reactivity
      // in the set, the variable is changed to trigger the reactivity
      get() {
        this.refreshDateRange
        return safeGet(this.modelValue, pathTo('date_range.end.value'))
      },
      set(v) {
        // In case we set an end range date and start is empty or disabled, set it default to now
        if (v && (!this.dateRangeStartEnabled || !this.dateRangeStart)) {
          this.setDateRangeStartDefault()
        }
        this.setterFn('date_range.end.value', v?.toISOString())
        this.refreshDateRange = !this.refreshDateRange
      }
    },
    dayOfWeekForm: {
      get() {
        return safeGet(this.modelValue, pathTo('day_of_week'))
      },
      set(v) {
        safeSet(this.modelValue, pathTo('day_of_week'), {
          ...v,
          enabled: this.daysOfWeekEnabled
        })
        this.$emit('update:modelValue', this.modelValue)
      }
    },
    startTimeRange() {
      return this.getTime('start')
    },
    endTimeRange() {
      return this.getTime('end')
    },
    computedDateTimeFormat() {
      return this.dateTimeFormat || this.$date.getDateTimeFormat()
    },
    translations() {
      return {
        monday: this.$t('common.days.monday'),
        tuesday: this.$t('common.days.tuesday'),
        wednesday: this.$t('common.days.wednesday'),
        thursday: this.$t('common.days.thursday'),
        friday: this.$t('common.days.friday'),
        saturday: this.$t('common.days.saturday'),
        sunday: this.$t('common.days.sunday')
      }
    }
  },
  methods: {
    setterFn(path, v) {
      safeSet(this.modelValue, pathTo(path), v)
      this.$emit('update:modelValue', this.modelValue)
    },
    updateDaySlot(v, day, prop, index) {
      const path = `${day}.slots.${index}`
      const pathProp = path + `.${prop}`
      const pathStart = path + `.start`
      safeSet(this.dayOfWeekForm, pathProp, v)
      if (prop === 'enabled' && v && !safeGet(this.dayOfWeekForm, pathStart)) {
        safeSet(this.dayOfWeekForm, pathStart, '00:00')
      }

      this.isTimeframeEnabled()
    },
    isTimeframeEnabled() {
      // Check if timeframes are enabled
      for (const key in this.dayOfWeekForm) {
        const day = this.dayOfWeekForm[key]
        if (day instanceof Object) {
          for (const s in day.slots) {
            const slot = day.slots[s]
            if (slot.enabled) {
              this.daysOfWeekEnabled = true
              return true
            }
          }
        }
      }
      this.daysOfWeekEnabled = false
      return false
    },
    validateEndAfterStart(start) {
      return (end) => start.replace(':', '') < end.replace(':', '')
    },
    removeDaySlot(day, key) {
      const path = `${day}.slots`
      const daySlots = safeGet(this.dayOfWeekForm, path)
      if (daySlots.length > 1) {
        safeSet(
          this.dayOfWeekForm,
          path,
          daySlots.filter((_, i) => i !== key)
        )
      } else if (daySlots.length === 1) {
        safeSet(this.dayOfWeekForm, path, [
          { ...initialSlot(), start: null, end: null }
        ])
      }
      this.isTimeframeEnabled()
    },
    addRow(day) {
      const path = `${day}.slots`
      const daySlots = safeGet(this.dayOfWeekForm, path)
      safeSet(
        this.dayOfWeekForm,
        path,
        daySlots.concat({ ...initialSlot(), enabled: true })
      )
      this.isTimeframeEnabled()
    },
    getTime(side = '') {
      const padTime = (t) => t.toString().padStart(2, 0)
      const path = pathTo(`date_range.${side}.value`)
      const date = safeGet(this.modelValue, path)
      const time = new Date(date)
      const hours = date ? padTime(time.getHours()) : '00'
      const minutes = date ? padTime(time.getMinutes()) : '00'
      return `${hours}:${minutes}`
    },
    // Had to put out as a custom validation because the rules were not getting the updates
    validateRangeStart(rule, value, cb) {
      if (!this.dateRangeStartEnabled || this.dateRangeStart) cb()
      cb(
        new Error(
          this.$t('pages.discounts.edit.form.date_range.error.start.required')
        )
      )
    },
    // Had to put out as a custom validation because the rules were not getting the updates
    validateRangeEnd(rule, value, cb) {
      if (!this.dateRangeEndEnabled || this.dateRangeEnd) cb()
      cb(
        new Error(
          this.$t('pages.discounts.edit.form.date_range.error.end.required')
        )
      )
    },
    validateRangeStartBeforeEnd(rule, value, cb) {
      if (
        !this.dateRangeStart ||
        !this.dateRangeStartEnabled ||
        !this.dateRangeEnd ||
        !this.dateRangeEndEnabled
      )
        cb()
      if (isBefore(new Date(this.dateRangeStart), new Date(this.dateRangeEnd)))
        cb()
      const errorMessage = rule.fullField.includes('end.value')
        ? this.$t('pages.discounts.edit.form.date_range.error.range.end')
        : this.$t('pages.discounts.edit.form.date_range.error.range.start')
      cb(new Error(errorMessage))
    },
    setDateRangeStartDefault() {
      this.dateRangeStartEnabled = true
      this.dateRangeStart = new Date()
    },
    validate(cb) {
      this.$refs.form.validate((valid) => {
        cb(valid && validateConstraints(this.dayOfWeekForm))
      })
    },
    openDatePicker(nextCheck, datePickerRef) {
      // Open select date
      if (nextCheck) {
        this.$nextTick(() => {
          this.$refs[datePickerRef].$el.querySelector('input').focus()
        })
      }

      // Disabled date range if start and end are unchecked
      this.dateRangeEnabled =
        this.dateRangeStartEnabled || this.dateRangeEndEnabled
    }
  }
}
</script>
