<template>
  <div>
    <select-holder
      :loading="loading"
      :placeholder="$t('components.multi_items_select.select.placeholder')"
      :shown-items="shownItems"
      @show-modal="$thModal.show('products-select')"
    />

    <th-modal
      name="products-select"
      height="800px"
      width="1200px"
      :title="$t('components.multi_items_select.products.select.title')"
    >
      <group-items-select
        v-model="selectedItems"
        resource="products"
        show-search-filter
        :filters="filtersList"
        :parse-search-query="parseSearchQuery"
        :resource-query="{
          deleted: false,
          limit: 50,
          location: currentLocation,
          ...extendQuery
        }"
        :normalise-keys="normalizeKeys"
        :result-filter="resultFilter"
        full-selected-object
      />

      <template #footer>
        <actions @cancel="handleCancel" @save="handleSave" />
      </template>
    </th-modal>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import GroupItemsSelect from '@/components/group-items-select'
import pAll from 'p-all'
import compare from 'just-compare'
import SelectHolder from './components/select-holder'
import Actions from './components/actions'

export default {
  components: {
    GroupItemsSelect,
    SelectHolder,
    Actions
  },
  props: {
    modelValue: {
      type: [Array, null],
      default: () => []
    },
    resources: {
      type: Object,
      default: () => ({})
    },
    extendQuery: {
      type: Object,
      default: () => ({})
    },
    extendFilters: {
      type: Array,
      default: () => []
    },
    resultFilter: {
      type: Function,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      selectedItems: [],
      shownItems: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    filtersList() {
      return [
        {
          name: 'barcode',
          type: 'input',
          label: this.$t(
            'pages.discounts.edit.products.select.filters.barcode.label'
          )
        },
        {
          name: 'product_group',
          type: 'resource-select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t('pages.products.all.filters.product_group.label'),
          appendToBody: true,
          options: {
            resource: 'productGroups'
          },
          formatValue: (value) => {
            if (!this.resources.productGroups) return value
            return (
              (
                this.resources.productGroups.find(
                  (item) => item.id === value
                ) || {}
              ).name || ' - '
            )
          }
        },
        {
          name: 'tax',
          type: 'resource-select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t(
            'pages.discounts.edit.products.select.filters.vat_account.label'
          ),
          appendToBody: true,
          options: { resource: 'taxes', options: this.resources.taxes || [] },
          formatValue: (value) => {
            if (!this.resources.taxes) return value
            return (
              (this.resources.taxes.find((item) => item.id === value) || {})
                .name || ' - '
            )
          }
        },
        {
          name: 'account',
          type: 'resource-select',
          placeholder: this.$t('common.inputs.placeholders.select'),
          label: this.$t(
            'pages.discounts.edit.products.select.filters.revenue_account.label'
          ),
          appendToBody: true,
          resourceQuery: { type: 'revenue', deleted: false },
          options: {
            resource: 'accounts'
          },
          formatValue: (value) => {
            if (!this.resources.accounts) return value
            return (
              (this.resources.accounts.find((item) => item.id === value) || {})
                .name || ' - '
            )
          }
        },
        ...this.extendFilters
      ]
    },
    normalizeKeys() {
      return (product) => ({
        ...product,
        title_main: this.$formatProduct(product)
      })
    }
  },
  watch: {
    modelValue(newItems, oldItems) {
      if (Array.isArray(newItems) && !compare(newItems, oldItems)) {
        this.selectedItems = this.modelValue
        this.fetchSelectedItemsData()
      }
    }
  },
  methods: {
    handleCancel() {
      this.selectedItems = this.modelValue || []
      this.$thModal.hide('products-select')
    },
    handleSave() {
      const products = this.selectedItems.length
        ? this.selectedItems
        : undefined
      this.$emit('update:modelValue', products)
      this.$thModal.hide('products-select')
      this.fillShownItems(products)
    },
    fillShownItems(items = []) {
      this.shownItems = [...items]
    },
    fetchInitialValue() {
      this.setSelectedItems()
    },
    setSelectedItems() {
      this.fillShownItems(this.modelValue)
      this.selectedItems = this.modelValue
    },
    async fetchSelectedItemsData() {
      if (
        Array.isArray(this.modelValue) &&
        this.modelValue.length &&
        typeof this.modelValue[0] === 'string'
      ) {
        try {
          this.loading = true
          const inst = th.products()
          const actions = this.modelValue.map((item) => () => inst.get(item))
          const res = await pAll(actions)
          const itemsData = res.map((item) => item.data)
          this.setSelectedItems(itemsData)
          this.$emit('update:modelValue', itemsData)
        } catch (err) {
          this.$logException(err, { trackError: false })
        } finally {
          this.loading = false
        }
      }
    },
    parseSearchQuery(query) {
      return { q: query, ...this.extendQuery }
    }
  }
}
</script>
